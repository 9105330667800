import { Breadcrumb, Col, Row } from 'antd';
import { Link, graphql } from 'gatsby';

import CollectionShop from '../components/collection.tsx/collection-shop';
import { GatsbyImage } from 'gatsby-plugin-image';
import { H1 } from '../components/shared/typography';
import React from 'react';
import Seo from '../components/Seo';
import styled from '@emotion/styled';
import useWindowWidth from '../components/shared/useWindowSize';

const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const HeadContainer = styled.div`
  justify-content: center;
  align-items: center;
  background: #00000085;
  min-height: 400px;
  z-index: 10;
  width: 100%;
  padding: 1rem 25vw;
  text-align: center;
  h1, p {
    color: white;
  }
  @media (max-width: 1000px) {
    padding: 1rem 10vw;
  }

  @media (max-width: 700px) {
    padding: 1rem 1rem;
  }
`

const HtmlContainer = styled.div`
  h3, h2, h1 {
    font-weight: bold;
    color: white;
  }
  font-size: 16px;
  line-height: 24px;
  color: white;
  text-align: center;
`;

const Collection: React.FC = ({ data: { collections } }: any) => {
  const collection = collections.nodes[0];
  const width = useWindowWidth()
  const type = collection.metafields.find((m: any) => m.key === 'type')
  
  return (
    <>
      <Seo
        title={collection.title}
        description={collection.description.slice(0, 155)}
        image={collection?.image?.originalSrc ?? ''}
      />
      {/* <Breadcrumb style={{padding: '15px 1rem', maxWidth: '1400px', margin: 'auto'}}>
        <Breadcrumb.Item>
          <Link to="/vine">Vine</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{collection.title}</Breadcrumb.Item>
      </Breadcrumb> */}

      {width < 700 &&<CollectionShop products={collection.products} title={collection.title} />}
      <Row style={{ position: 'relative' }} justify="center">
        <StyledImg
            image={collection?.image?.gatsbyImageData}
            alt={collection.title}
            imgStyle={{ objectFit: 'cover' }}
          />
          <HeadContainer>
                  <H1>{collection.title}</H1>
                  <HtmlContainer
                    dangerouslySetInnerHTML={{ __html: collection.descriptionHtml }}
                  />
          </HeadContainer>
      </Row>
      {width >= 700 &&<CollectionShop products={collection.products} title={collection.title} collectionType={type && type.value} />}
    </>
  );
};
export default Collection;

export const query = graphql`
  query ($title: String!) {
    collections: allShopifyCollection(filter: { title: { eq: $title } }) {
      nodes {
        title
        descriptionHtml
        description
        productsCount
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 1200)
          originalSrc
        }
        metafields {
          value
          key
        }
        products {
          descriptionHtml
          variants {
            storefrontId
            inventoryQuantity
            availableForSale
            compareAtPrice
            price
            selectedOptions {
              name
              value
            }
            image {
              gatsbyImageData
              originalSrc
            }
          }
          featuredImage {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 200)
            originalSrc
          }
          metafields {
            value
            key
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          title
          vendor
          tags
            collections {
            title
            metafields {
              value
            }
          }
        }
      }
    }
  }
`;
